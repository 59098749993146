import React, { useEffect } from "react";
import { useRouter } from "next/router";

const TrustpilotWidget = () => {
  const router = useRouter();

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [router.pathname]);

  return (
    <div
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="5419b6a8b0d04a076446a9ad"
      data-businessunit-id="60e5b2b36fa87d00016b7eb4"
      data-style-height="24px"
      data-style-width="100%"
      data-theme="light"
      data-style-alignment="center"
    >
      <a
        href="https://uk.trustpilot.com/review/sloths.co.uk"
        target="_blank"
        rel="noopener"
        className="text-xs font-medium text-gray-500"
      >
        Trustpilot Loading...
      </a>
    </div>
  );
};

export default TrustpilotWidget;

// for fetching all of the products
export const productsQuery = `
query Products {
  products(first: 250) {
    edges {
      node {
        id
        title
        handle
        description
        descriptionHtml
        addonsMetafield: metafield(namespace: "custom", key: "addons") {
          description
          value
        }
        shortDescriptionMetafield: metafield(namespace: "custom", key: "short_description") {
          description
          value
        }
        productDetailsMetafield: metafield(namespace: "custom", key: "product_details") {
          description
          value
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        compareAtPriceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        totalInventory
        images(first: 6) {
          edges {
            node {
              id
              originalSrc
              altText
            }
          }
        }
        variants(first: 250) {
          edges {
            node {
              id
              title
              price {
                amount
                currencyCode
              }
              sku
              selectedOptions {
                name
                value
              }
            }
          }
        }
        tags
        # Add any other fields you need here
      }
    }
  }
}



`;

// for Payment checkhout processing
export const checkoutMutationQuery = `
mutation CheckoutCreate($lineItems: [CheckoutLineItemInput!]!, $note: String) {
  checkoutCreate(input: { lineItems: $lineItems, note: $note }) {
    checkout {
      webUrl
    }
  }
}
`;

// Blog Query

export const BlogQuery = ` {
  blogByHandle(handle: "news") {
    articles(first: 250) {
      edges {
        node {
          id
          title
          handle
          contentHtml
          excerptHtml
          publishedAt
          blog {
            handle
          }
          image {
            id
            originalSrc
            altText
          }
          tags
        }
      }
    }
  }
}


`;

export const SingleArticleQuery = `query ($title: String!) {
  blogByHandle(handle: "news") {
    articles(first: 1, query: $title) {
      edges {
        node {
          id
          title
          contentHtml
          excerptHtml
          publishedAt
          blog {
            handle
          }
          image {
            id
            originalSrc
            altText
          }
        }
      }
    }
  }
}

`;

export const FilteredPrice = `
query Price {
    products(
      first: 132
      filters: { price: { min: 2.49, max: 2.49 } }
    ) {
      edges {
        node {
          handle
          priceRange {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }


`;

import { useProducts } from "@/hooks/products";
import React, { useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { motion } from "framer-motion";

const SearchBox = ({ onc }) => {
  const { products, isLoading } = useProducts();
  const [searchQuery, setSearchQuery] = useState(" ");
  const [filteredProducts, setFilteredProducts] = useState([]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filtered = products.filter(
      (product) =>
        !product.tags.includes("hidden") &&
        product.title.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  return (
    <>
      <motion.div
        key="searchBox"
        initial={{ y: "45%", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: "45%", opacity: 0, transition: { duration: 0.4 } }}
        transition={{ duration: 0.4, ease: "easeIn" }}
        className="z-50 bg-[#F4F8FF] shadow-xl rounded-xl h-auto py-4 w-[80vw] lg:w-[30vw] absolute top-20 right-6 lg:right-10"
      >
        <div className="w-full flex items-center justify-center">
          {isLoading ? (
            "Hold on..."
          ) : (
            <input
              className="w-[90%] rounded-lg bg-white outline-none p-2 text-sm"
              type="email"
              id="email"
              placeholder="Search products..."
              onChange={handleSearch}
              value={searchQuery}
              autoComplete="off"
            />
          )}
        </div>
        <div className="space-y-4 py-4 overflow-y-scroll h-auto max-h-[60vh]">
          {searchQuery !== "" ? (
            filteredProducts.length > 0 ? (
              filteredProducts.map((product, key) => (
                <Link key={key} href={`/products/${product.handle}`}>
                  <div
                    onClick={onc}
                    className="hover:bg-gray-200 border-b border-gray-100 py-4 cursor-pointer flex items-start space-x-2 px-4"
                    key={product.id}
                  >
                    <Image
                      src={product.images.edges[0].node.originalSrc}
                      width={100}
                      height={100}
                      className="rounded-2xl"
                    />

                    <div>
                      <h2>{product.title}</h2>
                      <p className="text-xs">
                        {product.description.slice(0, 100)}
                      </p>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <p className="text-center">No products found.</p>
            )
          ) : (
            <p className="text-center">Start typing to search products.</p>
          )}
        </div>
      </motion.div>

      <div
        onClick={onc}
        className=" absolute right-0 top-20 w-screen h-[90vh]"
      ></div>
    </>
  );
};

export default SearchBox;

import { storefront } from "@/helpers/store";
import { productsQuery } from "@/helpers/queries";
import React, { useState, useEffect } from "react";

export function useProducts() {
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await storefront(productsQuery);
      if (response.errors) {
        // Handle any errors returned by the API
        console.error(response.errors);
        setProducts([]);
      } else {
        const products = response.data.products.edges.map(({ node }) => node);
        setProducts(products);
      }
    } catch (error) {
      // Handle any network or parsing errors
      console.error(error);
      setProducts([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return { products, isLoading };
}

// fetch single product

// Fetch all the articles from news blog
export function useSingleProduct(title) {
  const [product, setProduct] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchSingleProduct = async () => {
    setLoading(true);

    try {
      const response = await storefront(`
      query SingleProduct {
        products(first: 1, query: "${title}") {
          edges {
            node {
              id
              title
              description
              
              priceRange {
                minVariantPrice {
                  amount
                  currencyCode
                }
                maxVariantPrice {
                  amount
                  currencyCode
                }
              }
       
              compareAtPriceRange {
                minVariantPrice {
                  amount
                  currencyCode
                }
                maxVariantPrice {
                  amount
                  currencyCode
                }
              }
              totalInventory
              images(first: 6) {
                edges {
                  node {
                    id
                    originalSrc
                    altText
                  }
                }
              }
              variants(first: 250) {
                edges {
                  node {
                    id
                    title
                    sku
                    price {
                      amount
                      currencyCode
                    }
                    
                  
                  }
                }
              }
              # Add any other fields you need here
            }
          }
        }
      }
      
      `);
      if (response.errors) {
        // Handle any errors returned by the API
        console.error(response.errors);
        setProduct([]);
      } else {
        const product = response.data.products.edges[0]?.node;

        setProduct(product);
      }
    } catch (error) {
      // Handle any network or parsing errors
      console.error(error);
      setProduct([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSingleProduct();
  }, [title]);

  return { product, isLoading };
}

import React, { useEffect, useState } from "react";
import Image from "next/image";
import { storefront } from "@/helpers/store";
import { motion } from "framer-motion";

import { checkoutMutationQuery } from "@/helpers/queries";
import Popup from "./Popup";
function Cart({ closeBtn, cart, removeFromCart, subTotal }) {
  const [totalAmount, setTotalAmount] = useState(0);
  const [confirmation, setConfirmation] = useState(false);
  const [giftMessage, setGiftMessage] = useState("");

  // Load gift message from local storage on component mount
  useEffect(() => {
    const storedGiftMessage = localStorage.getItem("giftMessage");
    if (storedGiftMessage) {
      setGiftMessage(storedGiftMessage);
    }
  }, []);

  const handleGiftMessage = (e) => {
    const message = e.target.value;
    setGiftMessage(message);
    localStorage.setItem("giftMessage", message);
  };

  const handleButtonClick = (message) => {
    setGiftMessage(message);
    localStorage.setItem("giftMessage", message);
  };

  const toggleConfirmPopup = () => {
    setConfirmation(!confirmation);
  };

  async function checkout() {
    const lineItems = Object.keys(cart).map((key) => {
      return {
        variantId: cart[key].variant.id,
        quantity: cart[key].qty,
      };
    });

    const lineItemsInput = lineItems.map(({ variantId, quantity }) => ({
      variantId,
      quantity,
    }));

    const { data } = await storefront(checkoutMutationQuery, {
      lineItems: lineItemsInput,
      note: giftMessage,
      mutation: `
        mutation CheckoutCreate($lineItems: [CheckoutLineItemInput!]!, $note: String) {
          checkoutCreate(input: { lineItems: $lineItems, note: $note }) {
            checkout {
              webUrl
            }
          }
        }
      `,
    });

    const { webUrl } = data.checkoutCreate.checkout;
    window.location.href = webUrl;
  }

  useEffect(() => {
    // Calculate the total amount
    const total = Object.keys(cart).reduce((accumulator, key) => {
      const { price, qty } = cart[key];
      return accumulator + parseFloat(price) * qty;
    }, 0);

    // Round the total amount to two decimal places
    const roundedTotal = total.toFixed(2);

    setTotalAmount(roundedTotal);
  }, [cart]);

  return (
    <div className=" flex items-center justify-end z-50  w-screen h-[87vh] lg:h-[85vh] right-0 py-10 fixed bottom-0">
      <motion.div
        key="cart"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.1 } }}
        transition={{ duration: 0.1, ease: "easeInOut" }}
        onClick={closeBtn}
        className="bg-black bg-opacity-75 lg:w-[50%] w-[10%] h-screen"
      ></motion.div>

      <motion.div
        key="cart"
        initial={{ x: "80%", opacity: 1 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: "80%", opacity: 0, transition: { duration: 0.3 } }}
        transition={{ duration: 0.3, ease: "easeIn" }}
        className="relative mt-24 lg:mt-0 py-16 lg:py-8 bg-[#F4F8FF] w-[90%] lg:w-[50%] h-auto "
      >
        {confirmation && (
          <Popup checkout={checkout} toggleConfirmPopup={toggleConfirmPopup} />
        )}
        <div className="flex items-center justify-between bg-[#F4F8FF] sm:rounded-tr-lg sm:rounded-tl-lg w-full px-4 h-auto py-4 ">
          <h2 className="py-2 text-2xl font-semibold text-[#294870]">
            Order Summary ({Object.keys(cart).length})
          </h2>

          <svg
            onClick={closeBtn}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-8 h-8 text-[#294870] cursor-pointer"
          >
            <path
              fillRule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        {/* table */}

        <div className="cartScroll px-2 lg:px-10 py-4 relative overflow-x-auto bg-[#F4F8FF]  w-full h-[80vh] overflow-y-scroll">
          {Object.keys(cart).map((key) => {
            return (
              <>
                <div
                  key={cart[key].id}
                  className="flex border-b border-gray-400 py-6 justify-between items-start"
                >
                  <div className="lg:w-[50%] flex items-start space-x-2">
                    <div className="w-[40%]">
                      <Image
                        className="rounded-2xl"
                        src={cart[key].image}
                        width={300}
                        height={300}
                      />
                    </div>
                    <div className="space-y-2">
                      <h3 className="text-sm lg:text-base font-semibold">
                        {cart[key].name}
                      </h3>
                      <div>
                        {cart[key].variant.title === "Default Title" ? (
                          <p className="text-xs lg:text-sm text-gray-400">
                            Type: Default
                          </p>
                        ) : (
                          <p className="text-xs lg:text-sm text-gray-400">
                            Type: {cart[key].variant.title}
                          </p>
                        )}
                        {cart[key].variant.sku && (
                          <p className="text-xs lg:text-sm text-gray-400">
                            Sku: {cart[key].variant.sku}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className=" py-2 flex flex-col justify-start items-center space-y-8">
                    <div>
                      <p className="text-sm lg:text-base font-semibold">
                        £{parseFloat(cart[key].price).toFixed(2)}
                      </p>
                    </div>
                    <div>
                      <svg
                        onClick={() => {
                          removeFromCart(
                            key,
                            1,
                            cart[key].price,
                            cart[key].name,
                            cart[key].image,
                            cart[key].variant
                          );
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="cursor-pointer w-6 h-6 text-[#294870] hover:scale-125 transition"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </>
            );
          })}

          {JSON.stringify(cart) === "{}" ? null : (
            <div className="py-4 mt-4 space-y-2 px-4  h-auto w-full bg-white border border-gray-300  rounded-[10px] ">
              <p className="text-xs">
                Free Gift Message &nbsp;{" "}
                <span className="text-gray-400">
                  {giftMessage.length} / 250
                </span>
              </p>
              <div className="space-y-3">
                <p className="text-xs text-[#6F6F6F]">
                  Write a message or{" "}
                  <span className="underline">start from these ideas</span>
                </p>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => {
                      handleButtonClick(
                        "Wishing the most amazing person I know a wonderful birthday! Your inner and outer beauty shine brightly, and you deserve only the absolute best, today and forever. ❤️"
                      );
                    }}
                    className="focus:bg-[#294870] hover:bg-[#294870] focus:text-white hover:text-white rounded-[5px] h-auto py-1 px-2 bg-[#F4F8FF] text-xs"
                  >
                    Happy Birthday
                  </button>
                  <button
                    onClick={() => {
                      handleButtonClick(
                        "I miss you so much and think about you all the time. Wishing you were here right now. I love you and can't wait to see you again!"
                      );
                    }}
                    className="focus:bg-[#294870] hover:bg-[#294870] focus:text-white hover:text-white rounded-[5px] h-auto py-1 px-2 bg-[#F4F8FF] text-xs"
                  >
                    I Miss You
                  </button>
                  <button
                    onClick={() => {
                      handleButtonClick(
                        "Sending you an abundance of love and positivity! Remember, you embody strength, you can absolutely handle this! You're in my thoughts, and I hope your new furry companion brings a spark of joy to your day. ❤️"
                      );
                    }}
                    className="focus:bg-[#294870] hover:bg-[#294870] focus:text-white hover:text-white rounded-[5px] h-auto py-1 px-2 bg-[#F4F8FF] text-xs"
                  >
                    Get Well
                  </button>
                  <button
                    onClick={() => {
                      handleButtonClick(
                        "This gift is here to hang around and bring a little extra joy to your day.  It's a reminder to slow down, embrace the moment, and find happiness in life's simple pleasures. Here's to a world of slow-paced fun and relaxation. Enjoy!"
                      );
                    }}
                    className="focus:bg-[#294870] hover:bg-[#294870] focus:text-white hover:text-white rounded-[5px] h-auto py-1 px-2 bg-[#F4F8FF] text-xs"
                  >
                    General
                  </button>
                </div>

                {/* text */}
                <textarea
                  className="text-xs text-gray-500 outline-none border border-gray-300 rounded-[10px] w-full py-2 px-2"
                  name=""
                  value={giftMessage}
                  onChange={handleGiftMessage}
                  id=""
                  cols="30"
                  rows="4"
                  maxLength={250}
                ></textarea>

                {/* note */}
                <p className="text-[#1E1E1E] text-xs">
                  <strong>Note:</strong> The name of the sender and the animal
                  is not included in the package. If you&apos;d like the
                  recipient to know who sent the gift and the animal&apos;s
                  name, please include them in your message above 👆🏼.
                </p>
              </div>
            </div>
          )}

          {/* calculations */}
          <div className="px-4 lg:px-0 space-y-4 w-full h-auto py-6">
            <div className="w-full flex items-center justify-between">
              <p className="font-medium">Subtotal</p>
              <p className="font-medium">£{totalAmount}</p>
            </div>

            <div className="w-full flex items-center justify-between">
              <p className="font-bold text-2xl"> Total</p>
              <p className="font-medium">£{totalAmount}</p>
            </div>
            <p className="text-sm text-red-600 text-center font-medium ">
              Please Note: Gift Boxes and accessories will be shipped
              separately!
            </p>
            {/* {totalAmount < 30 && (
              <p className="text-sm text-red-600 text-center font-medium ">
                Add £{parseFloat(30 - totalAmount).toFixed(2)} to get Free UK
                Shipping!
              </p>
            )} */}

            <div className=" w-full py-2 flex items-center justify-center">
              <button
                disabled={Object.keys(cart).length === 0 ? true : false}
                onClick={checkout}
                className="disabled:bg-gray-300 disabled:cursor-not-allowed font-semibold hover:bg-[#355b8d] bg-[#294870] text-white px-10 py-2 rounded-[31px]"
              >
                Checkout
              </button>
            </div>
          </div>
        </div>

        {/* table */}
      </motion.div>
    </div>
  );
}

export default Cart;

import "@/styles/globals.css";
import "@/styles/carousel.css";

// toasts css
import "react-toastify/dist/ReactToastify.css";

// swiper
import "swiper/css";
import "swiper/css/pagination";
import "swiper/swiper-bundle.min.css";

import Head from "next/head";
import Navbar from "@/components/Navbar";
import { useEffect, useState } from "react";
import Script from "next/script";

export default function App({ Component, pageProps }) {
  const [cart, setCart] = useState({});
  const [subTotal, setSubTotal] = useState(0);
  const [toggleCart, setToggleCart] = useState(false);

  const handleToggleCart = () => {
    setToggleCart(!toggleCart);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://embed.tawk.to/64a1b51894cf5d49dc6115d5/1h4bqmqe9";
    script.async = true;
    script.charSet = "UTF-8";
    script.setAttribute("crossorigin", "*");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    try {
      if (localStorage.getItem("slothCart")) {
        setCart(JSON.parse(localStorage.getItem("slothCart")));
      }
    } catch (error) {
      console.error(error);
      localStorage.clear();
    }
  }, []);

  const saveCart = (myCart) => {
    localStorage.setItem("slothCart", JSON.stringify(myCart));
    let subt = 0;
    let keys = Object.keys(myCart);
    for (let i = 0; i < keys.length; i++) {
      subt += myCart[keys[i]].price;
    }
    setSubTotal(subt);
  };

  const addToCart = (itemCode, qty, price, name, image, variant) => {
    let newCart = { ...cart }; // Create a copy of the cart object

    if (itemCode in newCart) {
      // newCart[itemCode].qty = newCart[itemCode].qty + qty;
      // newCart[itemCode].price = newCart[itemCode].price + price;
      newCart[itemCode] = { qty: 1, price, name, image, variant };
    } else {
      newCart[itemCode] = { qty: 1, price, name, image, variant };
    }

    setCart(newCart);
    saveCart(newCart);
  };

  const removeFromCart = (itemCode, qty, price, name, image) => {
    let newCart = { ...cart }; // Create a copy of the cart object
    delete newCart[itemCode];

    setCart(newCart);
    saveCart(newCart);
  };

  return (
    <>
      {/* Google Analytics and Google Ads */}
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-NPNE8ZKL7Y"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-NPNE8ZKL7Y', { cookie_flags: 'SameSite=None;Secure' });
    gtag('config', 'AW-702079071');
  `}
      </Script>

      {/* Facebook Pixel */}
      <Script strategy="afterInteractive">
        {`
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '2478879778867956');
    fbq('track', 'PageView');
  `}
      </Script>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=2478879778867956&ev=PageView&noscript=1"
        />
      </noscript>

      <Head>
        {/* meta data seo */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1"
        />
        <meta
          name="description"
          content="Discover the ultimate destination for sloth lovers! At Send a Sloth, we offer high-quality, eco-friendly sloth plush toys, unique sloth bundles, and more. Perfect for birthdays, anniversaries, and any special occasion. Delivered in fun, anonymous parcels. Free UK delivery over £30. Surprise your loved ones today!"
        />
        <meta
          name="keywords"
          content="Send a Sloth,Sloth Gifts,Unique Sloth Bundles,Eco-friendly Plush Toys,Sloth-themed Birthday Gifts,Anniversary Presents with Sloths,Send a Surprise Sloth,Adopt a Plush Sloth,Cute Sloth Presents,Buy Sloth Plush in UK,Best Eco-friendly Sloth Plush Toys,Unique Sloth-themed Birthday Gifts,Perfect Anniversary Presents with Sloths,Where to Buy Cute Sloth Presents in UK,Surprise Your Loved Ones with a Sloth Gift,High-quality Sloth Plush for All Ages,Adorable Sloth Bundles with Free UK Delivery over £30,Personalised Sloth Gifts for Special Occasions,Fun and Anonymous Sloth Parcel Delivery,Adorable and Sustainable Sloth Plush Toys"
        />
        <meta name="author" content="Mitesh Patel"></meta>
        <title>
          Send a Sloth - Adorable Sloth Gifts for Every Occasion | UK Delivery
        </title>

        {/* OG tags */}
        <meta
          property="og:title"
          content="Send a Sloth - Adorable Sloth Gifts for Every Occasion | UK Delivery"
        />
        <meta
          property="og:description"
          content="Discover the ultimate destination for sloth lovers! At Send a Sloth, we offer high-quality, eco-friendly sloth plush toys, unique sloth bundles, and more. Perfect for birthdays, anniversaries, and any special occasion. Delivered in fun, anonymous parcels. Free UK delivery over £30. Surprise your loved ones today!"
        />
        <meta property="og:url" content="https://sloths.co.uk/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://raw.githubusercontent.com/CodeStarGM/sloths/main/public/images/logo.png?token=GHSAT0AAAAAACABBN37WPMWV32E35SUNI4IZEX3T7A"
        />

        {/* links */}
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
      </Head>
      <Navbar
        cart={cart}
        addToCart={addToCart}
        removeFromCart={removeFromCart}
        subTotal={subTotal}
        handleToggleCart={handleToggleCart}
        toggleCart={toggleCart}
        setToggleCart={setToggleCart}
      />
      <Component
        addToCart={addToCart}
        cart={cart}
        setCart={setCart}
        handleToggleCart={handleToggleCart}
        {...pageProps}
      />
    </>
  );
}
